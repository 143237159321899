import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";

//import { ToastContainer } from "react-toastify";
import debug from "sabio-debug";
import * as serviceWorker from "./serviceWorker";
import usersService from "./services/usersService";
import vendorsService from "./services/vendorsService";
import {ToastContainer} from "react-toastify";

import "./index.css";
import "./services/serviceHelper";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/scss/main.scss";

import routes from "./pubRoutes.js"
import appRoutes from "./appRoutes.js"
import LoadingOverlay from "./components/pages/LoadingOverlay";

const App = React.lazy(() => import("./App"));
const Error404 = React.lazy(() => import("./components/pages/Error404"));
const Loading = React.lazy(() => import("./components/pages/Loading"));

const _logger = debug.extend("index");

const _userBase = usersService._userBase;
const _profileBase = usersService._profileBase;

// <script src="https://kit.fontawesome.com/9519e9a8d1.js" crossorigin="anonymous"></script>

class Root extends React.Component {
  constructor(props) {
    super(props);

    let login = () => {

      usersService.getCurrent()
      .then((response)=>{
          let userData = response.data.item;
          let userBase = { ..._userBase };
          userBase.id = userData.id;
          userBase.isLoggedIn = true;
          userBase.lastLoggedIn = userData.dateLastLoggedIn.toString().substr(0,3) === "000" ? null : userData.dateLastLoggedIn;
          userBase.email = userData.email
          let userRoles = [];
          for (let i = 0; i < userData.userRoles.length; i++) {
            userRoles.push(userData.userRoles[i].name);
          }
          userBase.roles = userRoles;

          let userProfile = { ..._profileBase };
          userProfile.firstName = userData.userProfile.firstName;
          userProfile.lastName = userData.userProfile.lastName;
          userProfile.middleName = userData.userProfile.middleName;
          userProfile.avatarUrl = userData.userProfile.avatarImageUrl;
          userProfile.businessImageUrl = userData.userProfile.businessImage;
          this.setState({currentUser: userBase, currentUserProfile: userProfile});
      })
    }

    let logout = () => {
      this.setState({ currentUser: usersService._userBase, currentUserProfile: usersService._profileBase});
    }

    let setBusinessImage = (url) => {
      this.setState({ currentUserProfile: {
        ... this.state.currentUserProfile,
        businessImageUrl: url
      }});
    }

    let refreshAlerts = () => {
      vendorsService.getAlerts()
      .then((response)=>this.setState({alerts: response.data.items}))
    }

    _userBase.login = login;
    _userBase.logout = logout;
    _userBase.refreshAlerts = refreshAlerts;
    _userBase.setBusinessImage = setBusinessImage;

    this.userBase = {};
    this.state = {
      hasMadeRequest: false,
      currentUser: {..._userBase },
      currentUserProfile: {..._profileBase},
      approvedRoutes: [],
      approvedRouteComponents: []
    }
    this.state.approvedRoutes = this.filterRoutes(this.state.currentUser.roles, appRoutes);
    this.state.approvedRouteComponents = this.state.approvedRoutes.map((rd)=>
      this.mapRoutes(rd, this.state.currentUser, this.state.currentUserProfile));
    
    this.state.publicComponents = routes.map((rd) =>
      this.mapRoutes(rd, this.state.currentUser, this.state.currentUserProfile)
    );
    _logger({state: this.state});
  }

  componentDidMount = () => {
    usersService.getCurrent()
    .then(this.getCurrentUserSuccess)

    vendorsService.getAlerts()
    .then((response)=>this.setState({alerts: response.data.items}));
  }

  getCurrentUserSuccess=(response)=>{
    this.state.currentUser.login(response.data.item);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if( prevState.currentUser.id !== this.state.currentUser.id ) {
        let newRoutes = this.filterRoutes(
          this.state.currentUser.roles,
          appRoutes
        );
        let approvedRouteComponents = newRoutes.map((rd) =>
          this.mapRoute(
            rd,
            this.state.currentUser,
            this.state.currentUserProfile
          )
        );
        let publicComponents = routes.map((rd) =>
          this.mapRoute(
            rd,
            this.state.currentUser,
            this.state.currentUserProfile
          )
        );
        let currentUser = this.state.currentUser;
        const newState = {
          ...prevState,
          currentUser: currentUser,
          currentUserProfile: this.state.currentUserProfile,
          approvedRoutes: newRoutes,
          approvedRouteComponents,
          hasMadeRequest: true,
          publicComponents,
        };
        this.setState(newState);
    }
  }

  mapRoute = (routeData, currentUser, currentUserProfile) => {
    let Component = routeData.component;
    return (
      <Route
        key={routeData.path}
        path={routeData.path}
        exact={routeData.exact}
        render={(props) => (
          <Component
            roles={routeData.roles}
            {...props}
            currentUser={currentUser}
            currentUserProfile={currentUserProfile}
          />
        )}
      />
    );
  };

  
  filterRoutes = (currentUserRoles = [], allRoutes) => {
    _logger ({currentUserRoles});

    var approvedRoutes = [];
    const routeData = [...allRoutes];
    currentUserRoles = currentUserRoles || [];
    for (var i = 0; i < currentUserRoles.length; i++){
      const userRole = currentUserRoles[i];
      _logger(userRole);

      for (var j = 0; j < routeData.length; j++) {
        const myCurrentRoute = routeData[j];

        var theseRoles =myCurrentRoute.roles;

        var isMatch = theseRoles.includes(userRole);

        if (isMatch === true) {
          approvedRoutes.push(myCurrentRoute);
          routeData.splice(j, 1);
          j = j -1;
          _logger({myIndex: j});
        }
      }
    }
    return approvedRoutes;
  }

  mapRoutes = (routeData, currentUser, currentUserProfile) => {
    let Component = routeData.component;
    return (
      <Route
        key={routeData.path}
        path={routeData.path}
        exact={routeData.exact}
        render={(props)=> (
          <Component
            roles={routeData.roles}
            {...props}
            currentUser={currentUser}
            currentUserProfile={currentUserProfile}
          />)}
        />
    )
  }

  render() {
    debug("rendering");
    return (
      <ScrollContext>
        <Suspense fallback={<LoadingOverlay {...this.props} />}>
          <Switch location={this.props.location}>
             {this.state.publicComponents}
             {this.state.currentUser.isLoggedIn ? (
                <App
                currentUser={this.state.currentUser}
                currentUserProfile={this.state.currentUserProfile}>
                  <Suspense fallback={<LoadingOverlay {...this.props} />}>
                    {this.state.approvedRouteComponents}
                  </Suspense>
                </App>
             ) : (
               <React.Fragment>
                 {this.state.hasMadeRequest ? (
                   <Route render={(props) => (
                     <Error404
                      {...props}
                      currentUser={this.state.currentUser}
                      currentUserProfile={this.state.currentUserProfile}
                     />
                   )}/>
                 ) : (
                   <Route
                   render={(props)=>(
                    <Loading
                    {...props}
                    currentUser={this.state.currentUser}
                    currentUserProfile={this.state.currentUserProfile}
                   />
                   )}/>
                 )}
               </React.Fragment>
             )}
          </Switch>
        </Suspense>
      </ScrollContext>
    )
  }
}

const RouterRoot = withRouter(Root);

ReactDOM.render(
  <BrowserRouter>
    <RouterRoot />
    <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover/>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
