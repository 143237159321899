import axios from "axios";
import logger from "sabio-debug";
import {API_HOST_PREFIX} from "./serviceHelper.js";

const _logger = logger.extend("userService");

const usersService = {
    endpoint: API_HOST_PREFIX + "/api/users",
    _userBase: {
        id: 0,
        isLoggedIn: false,
        lastLoggedIn: null,
        email: "",
        roles: [],
        tenantId: "Anonymous",
      },
    _profileBase: {
        firstName: "",
        lastName: "",
        middleName: "",
        avatarUrl: "",
        businessImageUrl: "",
      }
}

usersService.getCurrent = () => {
    const config = {
        method: "GET",
        url: `${usersService.endpoint}/current`,
        data: null,
        withCredentials: true,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

usersService.login = (payload) => {
    const config = {
        method: "POST",
        url: `${usersService.endpoint}/login`,
        data: payload,
        withCredentials: true,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    };
    _logger("login firing");
    return axios(config);
}

usersService.logout = () => {
    const config = {
        method: "POST",
        url: `${usersService.endpoint}/logout`,
        withCredentials: true,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

usersService.register = (payload) => {
    const config = {
        method: "POST",
        url: `${usersService.endpoint}/register`,
        data: payload,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

export default usersService;