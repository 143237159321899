import { lazy } from "react";

const pubRoutes = [
  {
    path: "/",
    exact: true,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import('./components/layout/LandingPage'))
  },
  {
    path: "/register",
    exact: true,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import("./components/pages/registration/Register")),
  },
  {
    path: "/logout",
    exact: true,
    roles: ["Admin", "User", "Vendor", "Contractor", "Employee", "Customer"],
    isAnonymous: false,
    component: lazy(() => import("./components/pages/Logout")),
},
  {
    path: '/login',
    exact: true,
    roles: ["Admin", "User", "Vendor", "Contractor", "Employee", "Customer"],
    isAnonymous: false,
    component: lazy(() => import('./components/pages/Login'))
  },
  {
    path: "/terms",
    exact: true,
    roles: [],
    isAnonymous: true,
    component: lazy(() => import('./components/layout/Terms'))
  },

//   {
//     path: "/password/forgot",
//     exact: true,
//     roles: [],
//     isAnonymous: true,
//     component: lazy(() => import("./components/pages/ForgotPasswordRequest")),
//   },

//   {
//     path: "/password/forgot/:slug",
//     exact: true,
//     roles: [],
//     isAnonymous: true,
//     component: lazy(() => import("./components/pages/ForgotPassword")),
//   },

//   {
//     path: "/password/reset",
//     exact: true,
//     roles: [],
//     isAnonymous: true,
//     component: lazy(() => import("./components/pages/ResetPassword")),
//   },

//   {
//     path: "/comingsoon",
//     exact: true,
//     roles: [],
//     isAnonymous: true,
//     component: lazy(() => import("./components/pages/CommingSoon")),
//   },

//   {
//     path: "/maintenance",
//     exact: true,
//     roles: [],
//     isAnonymous: true,
//     component: lazy(() => import("./components/pages/Maintenance")),
//   },

//   {
//     path: "/contact/us",
//     exact: true,
//     roles: [],
//     isAnonymous: true,
//     component: lazy(() => import("./components/pages/Maintenance")),
//   },
];

export default pubRoutes;
