import axios from "axios";
import {API_HOST_PREFIX} from "./serviceHelper.js";

const vendorsService = {
    endpoint: `${API_HOST_PREFIX}/api/vendors`
}

vendorsService.getAlerts = () => {
    const config = {
        method: "GET",
        url: `${vendorsService.endpoint}/alerts`,
        data: null,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

vendorsService.getBookmarks = () => {
    const config = {
        method: "GET",
        url: `${vendorsService.endpoint}/bookmarks`,
        data: null,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

vendorsService.updateBookmark = (payload) => {
    const config = {
        method: "PUT",
        url: `${vendorsService.endpoint}/bookmarks`,
        data: payload,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

vendorsService.searchProfile = (pIndex, pSize, query, industryId, city, state) => {
    let pi = pIndex || 0;
    let ps = pSize || 4;
    let q = query || "";
    let it = industryId || 0;
    let c = city || "";
    let s = state || 0;

    const config = {
        method: "GET",
        url: `${vendorsService.endpoint}/search?pi=${pi}&ps=${ps}&it=${it}&q=${q}&c=${c}&s=${s}`,
        data: null,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

vendorsService.getProfile = (businessId) => {
    const config = {
        method: "GET",
        url: `${vendorsService.endpoint}/${businessId}`,
        data: null,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

vendorsService.getCurrentProfile = () => {
    const config = {
        method: "GET",
        url: `${vendorsService.endpoint}`,
        data: null,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

vendorsService.createProfile = (payload) => {
    const config = {
        method: "POST",
        url: `${vendorsService.endpoint}`,
        data: payload,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

vendorsService.updateProfile = (payload) => {
    const config = {
        method: "PUT",
        url: `${vendorsService.endpoint}`,
        data: payload,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

vendorsService.updateProfileStatus = (payload) => {
    const config = {
        method: "PUT",
        url: `${vendorsService.endpoint}/status`,
        data: payload,
        crossdomain: true,
        headers: {"Content-Type": "application/json"},
    }

    return axios(config);
}

export default vendorsService;