import { lazy } from "react";

const commonRoutes = [
    // {
    //     path: "/home",
    //     exact: true,
    //     roles: ["Admin", "User", "Vendor", "Contractor", "Employee", "Customer"],
    //     isAnonymous: false,
    //     component: lazy(() => import("./components/pages/Home")),
    // },
]

const adminRoutes = [
    // {
    //     path: "/admin/test",
    //     exact: true,
    //     roles: ["Admin"],
    //     isAnonymous: false,
    //     component: lazy(() => import("./components/pages/LoadingOverlay")),
    // },
]

const vendorRoutes = [
    // {
    //     path: "/vendor",
    //     exact: true,
    //     roles: ["Admin", "Vendor"],
    //     isAnonymous: false,
    //     component: lazy(() => import("./components/pages/vendor/VendorDashboard")),
    // },
    {
        path: "/vendor/portal",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/portal/VendorPortal")),
    },
    {
        path: "/vendor/portal/bids/:id",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/portal/PortalBidView")),
    },
    {
        path: "/vendor/portal/:id",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/portal/CustomerProfile")),
    },
    {
        path: "/vendor/projects",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/projects/VendorProjects")),
    },
    {
        path: "/vendor/projects/return",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/profile/RegistrationReturn")),
    },
    {
        path: "/vendor/projects/:id",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/projects/VendorProjectsView")),
    },
    {
        path: "/vendor/projects/:id/open",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/bids/VendorBidForms")),
    },
    {
        path: "/vendor/projects/bids/:id",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/bids/VendorBidView")),
    },
    {
        path: "/vendor/projects/clients/:id",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/projects/VendorClientProjectsView")),
    },
    {
        path: "/vendor/profile",
        exact: true,
        roles: ["Admin", "Vendor"],
        isAnonymous: false,
        component: lazy(() => import("./components/pages/vendor/profile/VendorProfile")),
    },
]

const appRoutes = [
    ...commonRoutes,
    ...adminRoutes,
    ...vendorRoutes,
    ];

export default appRoutes;