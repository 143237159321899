import React from "react"; 
import Loading from "../../assets/images/overlays/loading_still.png";

class LoadingOverlay extends React.Component {

    render() {
        return (
            <div className="overlay-container">
                <div className="image-container">
                    <img className="loading-spinner" src={Loading} alt="..."/>
                </div>
                <div>
                    Loading
                </div>
            </div>
        )
    }
}

export default LoadingOverlay